import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function TextDiplay4() {
  return (
    <section class="bg-coolGray-100 text-coolGray-800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-center mt-8">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
            <span class="relative inline-block text-blue-900">Inscriptions</span>
          </h2>
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
          </div>
        </div>
      </div>
      <div class=" max-w-6xl p-6 mx-auto space-y-6 sm:space-y-12">
        <div
          // rel="noopener noreferrer"
          // href="#"
          class="block max-w-full gap-3 mx-auto sm:max-w-full group hover:no-underline focus:no-underline lg:grid lg:grid-cols-12 bg-coolGray-50"
        >
          <StaticImage
            src="../images/inscriptions.webp"
            alt=""
            class="object-cover w-full h-72 rounded sm:h-96 lg:col-span-7 bg-coolGray-500"
          />
          <div class="p-6 space-y-2 lg:col-span-5 text-xl flex items-center justify-center">
            {/* <h3 class="text-2xl font-semibold sm:text-4xl group-hover:underline group-focus:underline">
              Noster tincidunt reprimique ad pro
            </h3>
            <span class="text-xs text-coolGray-600">February 19, 2021</span> */}
            <p>
              <span className="font-medium text-blue-900">
                La campagne d’inscription pour l'année scolaire 2022-2023 est maintenant ouverte.
              </span>
              <br />
              <br />
              Pour inscrire votre enfant au sein du Collège Français de Kairouan (CFK), merci de
              bien vouloir remplir le formulaire{' '}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfDaquFWzJBdazwV57v4bJmxU_T75fkFk_dnVBuWuFJ_jkMXw/viewform"
                className="cursor-pointer font-bold text-gray-900 hover:text-blue-900"
                target="_blank"
                rel="noopenner noreferrer"
              >
                ci-joint.
              </a>
              <br />
            </p>
            <br />
            {/* <p>
              Vous serez contacté dans les plus brefs délais et vous serez tenu au courant pour les
              dates de nos tests d’entrée.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
}
