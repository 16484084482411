import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import AnimationRevealPage from '../components/AnimationRevealPage';
import Prix from '../components/Prix';
import TextDiplay4 from '../components/TextDisplay4';
import TextDiplay3 from '../components/TextDisplay3';

export default function Inscription() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collègé Jules Verne - Vie scolaire et pédagogique"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <TextDiplay4 />
        <Prix />
        <TextDiplay3 />
      </Layout>
    </AnimationRevealPage>
  );
}
